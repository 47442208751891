import _fs from "fs";
var exports = {};
var fs = _fs;
/**
 * async
 */

function isDirectory(filepath, cb) {
  if (typeof cb !== "function") {
    throw new Error("expected a callback function");
  }

  if (typeof filepath !== "string") {
    cb(new Error("expected filepath to be a string"));
    return;
  }

  fs.stat(filepath, function (err, stats) {
    if (err) {
      if (err.code === "ENOENT") {
        cb(null, false);
        return;
      }

      cb(err);
      return;
    }

    cb(null, stats.isDirectory());
  });
}
/**
 * sync
 */


isDirectory.sync = function isDirectorySync(filepath) {
  if (typeof filepath !== "string") {
    throw new Error("expected filepath to be a string");
  }

  try {
    var stat = fs.statSync(filepath);
    return stat.isDirectory();
  } catch (err) {
    if (err.code === "ENOENT") {
      return false;
    } else {
      throw err;
    }
  }

  return false;
};
/**
 * Expose `isDirectory`
 */


exports = isDirectory;
export default exports;